.App {
  text-align: center;
  font-size: medium;
  margin-top:5em
}
.everything {
  font-size: 0;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: whitesmoke;
// }
.heading {
  color: whitesmoke;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  // background-color:;
  // background-attachment: fixed !important;
  background-repeat: no-repeat;

  transition: 5s;
  color: #151717;
  font-family: Helvetica, Arial;
  font-size: 12pt;
}

.hidden {
  display: none;
}

/** Buttons **/

.btn--loginApp-link {
	text-align: center;
  background-color: transparent;
  border-radius: 2em;
  border: 0.2em solid #1ecd97;
  color: #1ecd97;
  cursor: pointer;
  font-size: 3vmin;
  padding: 0.7em 1.5em;
  text-transform: uppercase;
  transition: all 0.25s ease;
}

.btn--loginApp-link:hover {
  background: #1ecd97;
  color: #333;
}

.btn--login {
  margin: 0 auto;
}
.lyric-title-div{
  justify-content: flex-end;
}

.heading{
  color: #1ecd97;
  font: italic bold 100px Georgia, Serif;
  text-shadow: -4px 3px 0 gray, -14px 7px 0 rgb(34, 35, 33);
  transition: 5s;
}

/** Background **/

.background {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  filter: blur(8em) opacity(0.6);
  position: absolute;
}

.main-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  position: relative;
  width: 80%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
.app-heading {
	overflow: visible;
}
.spotify-img {
	max-height: inherit;
	width: inherit
}

.main-container {
  flex: 1;
}
.info-panel {
  position: -webkit-sticky;
  position: sticky;
	top: 1rem;
	max-width: 40rem;
	float: right;
}
.albumArt-card {
  padding: 10px;
  transition: 5s;

  //min-width: 800px;
  //max-width: 1000px;
}
.song-info-top-row {
  flex-wrap: nowrap;
  width: inherit;
}
.song-info-card {
  flex: 1;
}
.lyrics-title {
	padding: 2rem;
	max-width: 40rem;
}
.lyrics-heading {
	font-weight: bold;
}
.navigation-container {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav-button {
  width: 3rem;
}
.play-pause-button {
  width: 5rem;

}
.play-button {
  margin-right: -1.5rem;
}
// .refresh-button {
//   margin-right: -1.5rem;
// }
button.refresh-button-container{
    background:none !important;
    outline:none !important;
}
.refresh-button-container {
  transform: translateY(-20%);
  height: 5rem;
  padding: 1rem;
  background:none;
}
.refresh-button-container:active {
  transform: scale(1);
}
.landing-page-sales-text {
  font-family: Georgia;
  font-size: 2rem;
  margin: 0 auto;
  width: 50rem;
  color: rgb(34, 35, 33);
}
// Spinner
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
button {
  display: inline-block;
  border: none;
  flex:1;
  // padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  // opacity: 0;
  background: whitesmoke;
  // color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover
 {
  background: antiquewhite;
}

button:focus {
  outline: 0px solid #fff;
  outline-offset: -4px;
}

button > * {
  pointer-events: none;
}
.genius-url {
  font-style: italic;
  font-weight: 150;
}
body.modal-open{
  padding-right: 0!important;
  overflow-y:scroll !important;
}

.lyric-option-row {
  width:100%;
  padding: 0 0 0 0;
  height:50px;
  display: flex;
  flex-direction: row;
}
.lyric-option-title {
  padding: 1rem;
  text-align:left;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}
.thumbnail {
  min-width: 50px;
  max-width: 50px;
  max-height: 50px;
}
.modal-title {
  flex: 1;
}
.play-pause {
  display: flex;
}
.play-pause-button {
  flex: auto;
}
.close {
  flex: 0;
}