.lyrics-card {
  white-space: pre-wrap;
	font-size: 1.125em;
	line-height: 2;
  //background-color: whitesmoke;
  max-width: 40rem;
}
.lyrics-div {
  padding: 2rem;
  background-color: whitesmoke;
  max-width: 40rem;
}
