.song-blurb-card {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 2rem;
  margin-bottom: 1rem;
  transition: all 3s linear;
  white-space: pre-wrap;
  font-style: italic;
}
.blurb-spinner {
    margin: 0 auto;
}
/** Now Playing **/
// .player {
//   // position: -webkit-sticky;
//   // position: sticky;
//   // top: 1em;
//   background-color: whitesmoke;
//   padding: 10px 0px 10px 0px;
//   margin: 0 0 2rem 0;
//   //min-width: 800px;
//   //max-width: 1000px;
// }