.player-container {
  display: flex;
  flex-direction: column;
}
/** Now Playing **/
.player {
  // position: -webkit-sticky;
  // position: sticky;
  // top: 1em;
  background-color: whitesmoke;
  padding: 10px 0px 10px 0px;
  margin: 0 0 1rem 0;
  //min-width: 800px;
  //max-width: 1000px;
}

/** Progress **/
.progress-card {
  width: 100%;
}
.progress {
  border: 1px solid #eee;
  height: 6px;
  width: 100%;
  overflow: hidden;
}

.progress__bar {
  background-color: #eee;
  height: 4px;
}
.info-button {
  max-width: 1.5rem;
  height: fit-content;
  cursor: pointer;
  &:hover {
    filter:invert(86%) sepia(0%) saturate(2029%) hue-rotate(321deg) brightness(78%) contrast(95%)
  }
}
